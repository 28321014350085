.about{
  min-height: calc(100vh - 90px);
  background-image: url(../../public/images/bg-about.jpg);
  background-size: auto 55%;
  @screen lg {
    background-size: cover;
  }
  @screen 2xl{
    background-position-y: -50%;
  }
  &__cover{
    @screen lg {
      max-width: 550px;
      margin-left: -80px;
    }
    @screen 2xl{
      max-width: 710px;
      
    }
  }
  .forma{
    margin-top: -50px;
    @screen lg {
      margin-top: -96px;
    }
  }
  h1{
    line-height: 1.15;
  }
}