.circle{
  z-index: 1;
  @screen lg {
    width: 35%;
    left: -15%;
  }
  &.animated{
    animation: circle 12s ease 0s infinite alternate none;
  }
}
@keyframes circle {
	0% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
}