.team{
  @screen lg {
    min-height: calc(100vh - 90px);
    &::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 250px;
      height: 100%;
      background-color: #0E3175;
      @screen 2xl{
        width: 325px;
      }
    }
  }
  .slick-dots{
    bottom: calc(100% + 20px);
    text-align: left;
    margin-left: -10px;
    @screen lg{
      padding-left: calc(5rem - 10px);
      margin-left: 0px;
    }
    li{
      button{
        padding: 0;
        &::before{
          font-size: 10px;
          color: $color-blue;
          opacity: 0.3;
        }
      }
      &.slick-active{
        button{
          &::before{
            color: $color-blue;
            opacity: 1;
          }
        }
      }
    }
  }
  .quotes{
    max-height: 26px;
    margin-left: calc(50% - 15px);
    margin-top: 20px;
    margin-bottom: -40px;
  }
}