@font-face {
  font-family: 'Biotif Light';
  src: url(../../public/fonts/Biotif-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'Biotif';
  src: url(../../public/fonts/Biotif-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'Biotif Medium';
  src: url(../../public/fonts/Biotif-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'Biotif Semibold';
  src: url(../../public/fonts/Biotif-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'Biotif Bold';
  src: url(../../public/fonts/Biotif-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'Biotif Bold Italic';
  src: url(../../public/fonts/Biotif-BoldItalic.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'Biotif Black';
  src: url(../../public/fonts/Biotif-Black.ttf);
  font-weight: 900;
}

.f-light{
  font-family: 'Biotif Light';
}
.f-regular{
  font-family: 'Biotif';
}
.f-medium{
  font-family: 'Biotif Medium';
}
.f-semibold{
  font-family: 'Biotif Semibold';
}
.f-bold{
  font-family: 'Biotif Bold';
}
.f-bold-italic{
  font-family: 'Biotif Bold Italic';
}
.f-black{
  font-family: 'Biotif Black';
}
$color-blue: #2F59DF;
$bg-gray: #E7ECF2;
.c-blue{
  color: $color-blue;
}
.bg-blue{
  background-color: $color-blue;
}
.bg-gray{
  background-color: $bg-gray;
}
.forma{
  clip-path: polygon(6% 0%, 100% 0, 100% 88.5%, 93.5% 100%, 0 100%, 0% 11%);
  @screen lg {
    clip-path: polygon(12% 0%, 100% 0, 100% 77%, 87% 100%, 0 100%, 0% 22%);
  }
}
body{
  font-family: 'Biotif';
  background-color: $bg-gray;
}
section,
footer{
  z-index: 1;
  position: relative;
}
.partners{
  background-image: url(../../public/images/bg-contact.jpg);
  .logos{
    img{
      max-height: 30px;
      &:last-child{
        max-height: 40px;
      }
    }
  }
}
footer{
  border-color: #C6C6C6;
  p{
    color: #606060;
  }
}
.arrow-down{
  left: calc(50% - 15px);
}