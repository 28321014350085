.nav{
  padding-left: 15px;
  padding-right: 15px;
  @screen lg{
    padding-left: 100px;
    padding-right: 30px;
  }
  &__content{
    &--items{
      @media (max-width: 768px){
        width: calc(100% + 31px);
        top: 70px;
        padding-top: 15px;
      }
      .items{
        &__link{
          font-family: 'Biotif Light';
          letter-spacing: 2px;
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 300;
          padding-right: 10px;
          padding-left: 30px;
          @screen lg{
            padding-right: 10px;
            padding-left: 12px;
            color: $color-blue
          }
          @screen 2xl{
            font-size: 18px;
          }
          &:not(:last-child){
            @screen lg{
              margin-right: 20px;
            }
            @screen 2xl{
              margin-right: 40px;
            }
          }
          &--external{
            color: #FFFFFF;
            background-color: $color-blue;
            border-radius: 10px 0 10px 10px;
            @screen 2xl {
              padding-top: 10px;
              padding-bottom: 7px;
            }
          }
          &:hover{
            font-family: 'Biotif Bold';
            letter-spacing: 1.5px;
            .items__link--subitems{
              font-family: 'Biotif Light';
              letter-spacing: 2px;
              top: 93px;
              @screen 2xl{
                top: 99px;
              }
            }
          }
          &--subitems{
            left: -40px;
            top: -450px;
            li{
              background-color: $color-blue;
              font-size: 16px;
              &:hover{
                font-family: 'Biotif Bold';
                letter-spacing: 1.5px;
                background-color: #224BB2;
              }
              span{
                min-width: 38px;
              }
            }
          }
          // .fa{
          //   margin-top: 3px;
          // }
        }
      }

    }
  }
}