.services{
  &__service{
    @screen lg {
      min-height: calc(100vh - 90px)
    }
    .phrase{
      border-color: $color-blue;
      @screen 2xl{
        font-size: 1.3rem;
      }
    }
    &--media{
      img{
        @screen lg {
          width: 90%;
        }
      }
    }
  }
}